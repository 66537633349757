@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.footer {
  margin: rem(50) 0 0;
  padding: rem(115) 0;
  border-top: rem(0.5) solid rgba(#e6e6e6, 0.8);

  @media (max-width: $mobile) {
    border-top: none;
    padding: 0 0 rem(90);
  }

  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(170);

    @media (max-width: $mobile) {
      border-top: rem(0.5) solid rgba(#e6e6e6, 0.8);
      padding: rem(20) 0 0;
      gap: rem(50);

      justify-content: space-between;
      flex-direction: row;
    }

    &__row {
      display: flex;
      justify-content: space-between;
    }

    &__content {
      @media (max-width: $mobile) {
        flex: 1 1 auto;
        display: flex;
        gap: rem(60);
        flex-direction: column;

        &:nth-child(2) {
          flex: 0 1 auto;
        }
      }
    }
  }
  &Col {
    display: flex;
    flex-direction: column;
    flex: 0 1 rem(560);

    @media (max-width: $mobile) {
      flex: 0 0 auto;

      div {
        font-size: rem(15);
        font-weight: 355;
      }
    }

    &:nth-child(2) {
      flex: 0 1 rem(470);
      @media (max-width: $mobile) {
        flex: 0 0 auto;
      }
    }
  }
  &Row {
    display: flex;
    &__col {
      display: flex;
      align-items: flex-start;
      gap: rem(2);
      flex-direction: column;
      flex: 0 0 50%;

      @media (max-width: $mobile) {
        div {
          font-size: rem(15);
          font-weight: 355;
        }
      }

      &:nth-child(2) {
        @media (max-width: $mobile) {
          align-items: flex-start;
        }
      }

      a {
        transition: all $time $tr;
        &:hover {
          opacity: 0.7;
        }
      }

      &.right {
        align-items: flex-end;
      }
      &.bottom {
        justify-content: flex-end;
      }

      &Sound {
        margin: 0 0 0 auto;
      }
    }
  }

  .social {
    display: flex;
    gap: rem(4);

    &__item {
      width: rem(40);
      height: rem(40);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
