@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.aboutCon {
  margin: rem(120) 0 0;

  @media (max-width: $tablet) {
    margin: rem(80) 0 0;
  }
}

.content {
  margin: -100vh 0 0;
  position: relative;
  z-index: 1;
  background-color: cl(bkg);
}
