@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.button {
  background-color: cl(whte);
  padding: rem(10) rem(65);
  border: rem(1) solid cl(whte);

  font-weight: 365;
  font-size: rem(16);
  line-height: 122%;
  letter-spacing: -0.01em;
  color: cl(blck);

  transition: all $time $tr;

  &:hover {
    // background-color: cl(whte, 0.7);
    background-color: transparent;
    color: cl(whte);
  }
}
