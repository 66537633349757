@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.card {
  flex: 1 1 calc(25% - rem(7.5));

  @media (max-width: $tablet) {
    flex: 1 1 calc(50% - rem(5));
  }
  @media (max-width: $mobile) {
    flex: 1 1 calc(100%);
  }

  &__img {
    position: relative;
    height: 0;
    padding: 0 0 127%;

    @media (max-width: $mobile) {
      padding: 0 0 115%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__name {
    margin: rem(10) 0 0;
    font-weight: 435;
    font-size: rem(24);
    line-height: 150%;
    letter-spacing: -0.01em;
    color: cl(whte);

    @media (max-width: $mobile) {
      margin: rem(5) 0 0;
    }
  }
  &__description {
    margin: rem(2) 0 0;
    max-width: calc(100% - rem(70));

    @media (max-width: $mobile) {
      margin: rem(3) 0 0;
      max-width: calc(100%);
    }
  }
}
