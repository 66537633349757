@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.main {
  // position: fixed;
  width: 100%;
  &__img {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center bottom;
    }
  }
  &Body {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      text-align: center;

      br {
        display: none;

        @media (max-width: $mobile) {
          display: block;
        }
      }
    }
  }
  &Bottom {
    position: absolute;
    bottom: rem(20);
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile) {
      bottom: rem(10);
    }

    &__time {
      @media (max-width: $mobile) {
        font-size: rem(15);
      }
    }
  }
}
