@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.about {
  &Body {
    padding: rem(100) 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $tablet) {
      padding: rem(100) 0 rem(20);
    }

    &__title {
      width: 100%;
      max-width: rem(790);
      margin: 0 0 0 auto;

      @media (max-width: $tablet) {
        max-width: 100%;
      }
    }
    &__content {
      margin: rem(80) 0 0;
      display: flex;
      justify-content: space-between;

      @media (max-width: $tablet) {
        flex-direction: column-reverse;
      }

      &Row {
        display: flex;
        gap: rem(60);
        width: 100%;
        max-width: rem(790);

        @media (max-width: $tablet) {
          max-width: 100%;
          gap: rem(30);
          flex-direction: column;
        }
      }
      &Text {
        flex: 0 1 rem(320);

        @media (max-width: $tablet) {
          flex: 0 0 auto;
        }
      }
    }
    &__year {
      @media (max-width: $tablet) {
        margin: rem(107) 0 0;
      }
    }
  }
}
