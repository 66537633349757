@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.header {
  position: absolute;
  z-index: 10;
  top: rem(15);
  left: 0;
  width: 100%;

  &Body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(26);

    &__logo {
      display: flex;
      align-items: center;
      gap: rem(8.5);
      max-width: rem(355);

      font-weight: 365;
      font-size: rem(16);
      line-height: 122%;
      letter-spacing: -0.01em;
      color: cl(whte);

      @media (max-width: $mobile) {
        max-width: rem(306);
      }

      svg {
        width: rem(77);
        height: auto;
      }
      span {
        flex: 0 0 auto;
        width: rem(1);
        height: rem(12);
        background-color: cl(whte);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left center;
      }
    }
    &__burger {
      font-weight: 355;
      font-size: rem(15);
      line-height: 125%;
      letter-spacing: -0.02em;
      color: cl(whte);
      padding: 0 0 rem(1);
    }
    &__links {
      ul {
        display: flex;
        gap: rem(22);

        li {
          a,
          div {
            cursor: pointer;
            font-weight: 365;
            font-size: rem(16);
            line-height: 122%;
            letter-spacing: -0.01em;
            color: cl(whte);
          }
        }
      }
    }
  }
}

.menu {
  position: fixed;
  z-index: 9;
  inset: 0;
  background-color: cl(bkg);

  transform: translate(0, -100%);
  pointer-events: none;
  user-select: none;

  transition: all $time $tr;

  &.open {
    transform: translate(0);
    pointer-events: unset;
    user-select: unset;
  }

  & > div {
    height: 100%;
  }

  &Body {
    padding: rem(110) 0 0;
    display: flex;
    flex-direction: column;
    gap: rem(30);
    height: 100%;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &:nth-child(1) {
        align-items: flex-start;
      }
    }
    &__links {
      ul {
        display: flex;
        flex-direction: column;
        gap: rem(3);
        li {
          a,
          div {
            font-weight: 355;
            font-size: rem(15);
            line-height: 125%;
            letter-spacing: -0.02em;
            color: cl(whte);
          }
        }
      }
    }
    &__info {
      font-weight: 355;
      font-size: rem(15);
      line-height: 125%;
      letter-spacing: -0.02em;
      color: cl(whte);
    }
  }
}
