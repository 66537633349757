@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.team {
  padding: rem(150) 0 rem(140);

  @media (max-width: $mobile) {
    padding: rem(100) 0 rem(120);
  }

  &__title {
  }
  &Body {
    margin: rem(80) 0 0;
    display: flex;
    gap: rem(50) rem(10);
    flex-wrap: wrap;

    @media (max-width: $mobile) {
      gap: rem(60) rem(10);
      margin: rem(50) 0 0;
    }
  }
}
