@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.input {
  &__title {
  }
  &__item {
    width: 100%;
    margin: rem(6) 0 0;
    padding: rem(8) rem(10) rem(12);
    height: rem(40);
    background-color: cl(blck);
    border: rem(0.5) solid rgba(248, 248, 248, 0.2);

    font-weight: 365;
    font-size: rem(16);
    line-height: 122%;
    letter-spacing: -0.01em;
    color: cl(whte);
  }
  .error__text {
    font-weight: 360;
    font-size: rem(11);
    line-height: 122%;
    letter-spacing: -0.01em;
    color: cl('red');
  }
}
