@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.title {
  font-weight: 380;
  font-size: rem(100);
  line-height: 95%;
  letter-spacing: -0.06em;
  color: cl(whte);

  @media (max-width: $mobile) {
    font-size: rem(50);
  }

  &.big {
    @media (max-width: $mobile) {
      font-size: rem(85);
    }
  }
}
