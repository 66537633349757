@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.studios {
  padding: rem(132) 0 rem(100);

  @media (max-width: $mobile) {
    padding: rem(100) 0 rem(50);
  }

  &Body {
    display: flex;
    gap: rem(100);
    justify-content: space-between;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: rem(0);
    }

    &__content {
      display: flex;
      flex-direction: column;
    }
    &__title {
    }
    &__text {
      margin: rem(80) 0;
      max-width: rem(346);
      @media (max-width: $tablet) {
        margin: rem(50) 0;
        max-width: 100%;
      }
    }
    &__switch {
      margin: auto 0 0;
      position: relative;
      width: rem(343);
      height: rem(343);
      border: rem(0.5) solid rgba(248, 248, 248, 0.2);
      cursor: pointer;

      &:hover {
        .studiosBody__switchItem {
          &:nth-child(1) {
            opacity: 0.7;
          }
          &:nth-child(2) {
          }
        }
      }
      &.switched {
        .studiosBody__switchItem {
          &:nth-child(1) {
            z-index: 0;
          }
          &:nth-child(2) {
            z-index: 1;
          }
        }
        &:hover {
          .studiosBody__switchItem {
            &:nth-child(1) {
              opacity: 1;
            }
            &:nth-child(2) {
              opacity: 0.7;
            }
          }
        }
      }

      @media (max-width: $tablet) {
        margin: rem(10) 0 0;
        width: 100%;
        padding: 0 0 61%;
        height: 0;
      }

      &Item {
        position: absolute;
        inset: 0;

        transition: all $time $tr;

        &:nth-child(1) {
          z-index: 1;
        }
        &:nth-child(2) {
          z-index: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__img {
      cursor: pointer;
      position: relative;
      min-width: 0;
      flex: 0 1 rem(785);

      &:hover {
        .studiosBody__imgCount {
          opacity: 1;
        }
      }

      @media (max-width: $tablet) {
        flex: 0 0 auto;
      }

      &Nav {
        display: flex;
        position: absolute;
        z-index: 2;
        inset: 0;

        span {
          flex: 0 0 50%;
          &:hover {
            // background-color: cl(blck, 0.25);
          }
        }
      }

      &Count {
        position: absolute;
        z-index: 2;
        // left: rem(40);
        // bottom: rem(35);

        font-weight: 365;
        font-size: rem(22);
        line-height: 122%;
        letter-spacing: -0.01em;
        text-transform: capitalize;
        color: rgba(248, 248, 248, 0.4);

        opacity: 0;
        pointer-events: none;

        transition: all $time $tr;

        @media (max-width: $mobile) {
          opacity: 1;
          left: rem(40) !important;
          top: auto !important;
          bottom: rem(35) !important;
        }
      }
      &Slider {
        position: relative;
        z-index: 1;
        min-width: 0;
        overflow: hidden;
        border: rem(0.5) solid rgba(248, 248, 248, 0.2);

        .slide {
          position: relative;
          height: 0;
          padding: 0 0 102%;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
