@font-face {
  font-family: Sequel Sans;
  font-display: swap;
  src: url('../fonts/Sequel Sans Roman Head.woff2') format('woff2'),
    url('../fonts/Sequel Sans Roman Head.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Sequel Sans;
  font-display: swap;
  src: url('../fonts/Sequel Sans Heavy Head.woff2') format('woff2'),
    url('../fonts/Sequel Sans Heavy Head.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Sequel Sans;
  font-display: swap;
  src: url('../fonts/Sequel Sans Medium Head.woff2') format('woff2'),
    url('../fonts/Sequel Sans Medium Head.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
