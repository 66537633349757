@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.form {
  padding: rem(120) 0 rem(110);

  @media (max-width: $mobile) {
    padding: rem(100) 0 rem(50);
  }

  &__title {
  }
  &Body {
    margin: rem(80) 0 0;
    display: flex;

    @media (max-width: $mobile) {
      margin: rem(50) 0 0;
      flex-direction: column;
      gap: rem(60);
    }

    &__col {
      flex: 0 0 calc(50%);

      @media (max-width: $mobile) {
        flex: 1 1 auto;
      }

      &.formContent {
        display: flex;
        flex-wrap: wrap;
        gap: rem(15);

        .form {
          &__input {
            flex: 0 0 calc(50% - rem(7.5));

            &.textarea {
              flex: 0 0 100%;
            }
          }

          &__btn {
            @media (max-width: $mobile) {
              margin: rem(10) 0 0;
              width: 100%;
            }
          }
        }
      }

      &Text {
        max-width: rem(350);
        @media (max-width: $mobile) {
          max-width: 100%;
        }
      }
      &Contacts {
        margin: rem(110) 0 0;

        @media (max-width: $mobile) {
          margin: 0;
        }

        .item {
          &:not(:first-child) {
            margin: rem(20) 0 0;
            @media (max-width: $mobile) {
              margin: rem(16) 0 0;
            }
          }
          &__title {
            font-weight: 370;
            font-size: rem(16);
            line-height: 122%;
            letter-spacing: -0.01em;
            color: cl(whte);
            @media (max-width: $mobile) {
              font-size: rem(15);
            }
          }
          &__content {
            font-weight: 360;
            font-size: rem(16);
            line-height: 122%;
            letter-spacing: -0.01em;
            color: cl(whte);

            transition: all $time $tr;

            @media (max-width: $mobile) {
              font-size: rem(15);
            }

            &:hover {
              // color: cl(whte, 0.7);
            }
          }
        }
      }
    }
  }
}
