@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.text {
  font-weight: 360;
  font-size: rem(16);
  line-height: 122%;
  letter-spacing: -0.01em;
  color: cl(whte);

  @media (max-width: $mobile) {
    font-size: rem(17);
  }
}
