// ===============
// colors palette
// ===============

$colors: (
  'whte': #f8f8f8,
  'blck': #111111,
  'bkg': #020202,
  'red': #ff0303,
  'line-20%': rgba(#f8f8f8, 0.2),
  'line-80%': rgba(#e6e6e6, 0.8),
);

//========================================================================================================================================================

:root {
  @if $colors {
    @each $key, $value in $colors {
      --color-#{$key}: #{$value};
      --color-#{$key}--rgb: #{hextorgb($value)};
    }
  }

  --font-primary: #{$fontFamily};
}
