@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.sound {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(32);
  height: rem(32);

  @media (max-width: $mobile) {
    width: rem(24);
    height: rem(24);
  }

  audio {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &__on {
  }
  &_off {
  }
}
