@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.hover {
  position: relative;
  overflow: hidden;

  &:hover {
    .hover__item {
      &:nth-child(1) {
        transform: translate(0, -100%);
      }
      &:nth-child(2) {
        transform: translate(0);
      }
    }
  }

  &__item {
    transition: all $time $tr;
    &:nth-child(1) {
    }
    &:nth-child(2) {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(0, 100%);
    }
  }
}
